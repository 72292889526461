import React from 'react';

interface State {
  hasError: boolean;
}

interface Props {
  children: JSX.Element;
}

interface ErrorInfo {
  componentStack: string;
}

class ErrorBoundary extends React.Component {
  state: State;
  props: Props;

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /* eslint-disable class-methods-use-this, no-console */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof Error) {
      console.log('error message:', error.message);
    }
    console.log('errorInfo: ', errorInfo);
  }
  /* eslint-enable class-methods-use-this, no-console */
  render(): JSX.Element | null {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
